<template>
  <div class="klib-main-container">
    <keep-alive>
    <klib-home-main></klib-home-main>
    </keep-alive>
  </div>

</template>

<script>
import KlibHomeMain from '@/layout/KlibHomeMain.vue'
import PasswordVue from "../knowledgeBaseExport/components/Password";
export default {
  name: "index.vue",
  components: {
    KlibHomeMain
  },
}
</script>

<style lang="scss" scoped>
.klib-main-container {
  /* background-color: #F3F4F7; */
  height: 100%;
  background-image: linear-gradient(-30deg, #616BF2, #6F42EB);
  position: relative;
  ::v-deep .klib-home-page-container .klib-home-main {
    overflow: auto !important;
  }
}
</style>
