var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "edit-password-dialog vertical-middle-dialog",
      attrs: {
        title: "输入密码",
        visible: _vm.dialogVisible,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        width: "480px",
        "append-to-body": true,
        center: "",
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "form-wrap",
          attrs: {
            model: _vm.formObj,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "text account-text",
              attrs: { label: "密码", prop: "password" },
            },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formObj.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.formObj, "password", $$v)
                  },
                  expression: "formObj.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "text account-text",
              attrs: { label: "确认密码", prop: "checkPassword" },
            },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formObj.checkPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.formObj, "checkPassword", $$v)
                  },
                  expression: "formObj.checkPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.onConfirm },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }