<template>
  <el-dialog
    title="输入密码"
    :visible="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="edit-password-dialog vertical-middle-dialog"
    width="480px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-form
      ref="formRef"
      :model="formObj"
      :rules="rules"
      label-width="80px"
      label-position="left"
      class="form-wrap">
      <el-form-item label="密码" prop="password" class="text account-text">
        <el-input v-model="formObj.password" type="password">
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassword" class="text account-text">
        <el-input v-model="formObj.checkPassword" type="password">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" class="btn" @click="onConfirm">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.formObj.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        formObj: {
          password: '',
          checkPassword: ''
        },
        rules: {
          password: [
            { required: true, trigger: "blur", message: "密码不能为空" },
            { min: 5, max: 18, message: "长度在 5 到 18 个字符", trigger: "blur" }
          ],
          checkPassword: [
            { required: true, trigger: "blur", message: "确认密码不能为空" },
            { validator: validatePass, trigger: 'blur' }
          ]
        }
      }
    },
    watch: {
      dialogVisible(newVal, oldVal) {
        this.formObj = {
          password: '',
          checkPassword: ''
        }
        this.$nextTick(() => {
          this.$refs['formRef'] && this.$refs['formRef'].clearValidate()
        })
      }
    },
    methods: {
      onConfirm() {
        this.$refs['formRef'].validate(async (valid) => {
          if (valid) {
            this.$emit('success', this.formObj.password)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handleClose() {
        this.$emit('close', false)
      }
    }
  }
</script>
<style lang="scss" scoped>
.edit-password-dialog {
  
  ::v-deep.el-dialog {
    padding-bottom: 20px;
    height: 320px !important;
    .el-dialog__header {
      background-color: #fff;
      color: #333;
      font-weight: 600;
      border: none;
    }
    .el-dialog__body {
      
      padding: 25px 25px 0 !important;
      .form-wrap {
        .el-form-item__label {
          color: #555 !important;
          font-weight: 500;
        }
      }
      
    }
    
    .el-dialog__footer {
      background-color: #fff;
      height: 52px;
      border: none;
      .btn {
        width: 100px;
        height: 32px;
      }
    }
  }
}
</style>